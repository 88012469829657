import React from 'react';
// Custom Tiger21 imports
import CommentEngagementButtons from './CommentEngagementButtons';
import CommentItemLevelTwo from './CommentItemLevelTwo';
// import NewLevelTwoCommentForm from './NewLevelTwoCommentForm';
// STREAM imports
import { CommentItem } from 'react-activity-feed';

const CommentItemLevelOne = (props: any) => {
  if (props.comment.children_counts?.comment > 0) {
    // Because we show LevelOneComments in oldest to newest order, we want to
    // do that for LevelTwoComments too, but there is in-built option to do that,
    // so we reverse the order of the children array.
    // NOTE: We clone the child comments before reversing them
    // because of this: https://stackoverflow.com/a/57569141/80426
    let nestedComments = [...props.comment.latest_children.comment];
    let resersedNestedComments = nestedComments.reverse();
    return (
      <React.Fragment>
        {/* Level one comment item that _has_ some child level two comment items */}
        <CommentItem comment={props.comment} />
        <CommentEngagementButtons
          comment={props.comment}
          parentComment={props.comment}
        />
        {resersedNestedComments.map((childComment, index) => {
          return (
            <CommentItemLevelTwo
              key={index}
              comment={childComment}
              parentComment={props.comment}
            />
          );
        })}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* Level one comment item that _does not have_ any child level two comment items */}
        <CommentItem comment={props.comment} />
        <CommentEngagementButtons
          comment={props.comment}
          parentComment={props.comment}
        />
      </React.Fragment>
    );
  }
};

export default CommentItemLevelOne;
