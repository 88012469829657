import React from 'react';
import { FormEvent } from 'react';
// Custom Tiger21 imports
import { TDiscussionFeedProps } from './DiscussionFeed';
// STREAM imports
// todo
// Material UI imports
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// Other imports
import { Buffer } from 'buffer';

// Intersection of Props
type TNewPostFormProps = TDiscussionFeedProps & {
  formToggle: any;
};

const NewPostForm = (props: TNewPostFormProps) => {
  const { palette } = useTheme();
  // Manage all data/fields of the "new post" form via react state.
  // TODO why should we use formik here???
  const [formData, setFormData] = React.useState({
    subject: '',
    body: '',
    // TODO attachments
  });

  /**
   * Returns human friendly display name for a given network slug.
   *
   * @example
   * For slug `C--member_to_member_network` returns `Member To Member Network`.
   *
   * @example
   * For slug `C--philanthropy` returns `Philanthropy`.
   *
   * @param networkSlug
   * @returns Human friendly display name for networkSlug.
   */
  function getNetworkDisplayNameFromSlug(networkSlug: string) {
    let slugWithoutPrefix = networkSlug.replace(/^C--/, '');
    let networkDisplayName = slugWithoutPrefix
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return networkDisplayName;
  }

  // TODO TSDoc Comment: get user_id value from payload data of JWT token
  function getUserIdFromJWTToken(token: string) {
    const payload = token.split('.')[1];
    const decodedPayload = Buffer.from(payload, 'base64').toString('ascii');
    const { user_id } = JSON.parse(decodedPayload);
    return user_id;
  }

  // TODO what specific event type is common to all form fields?
  function handleFormChange(event: any) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }

  // TODO should post button be handled in a async function???
  const handleFormSubmitAsync = async (
    event: FormEvent<HTMLFormElement> | KeyboardEvent
  ) => {
    // event.preventDefault();
    console.log(event);
    alert('TODO post a test post');
  };

  function handleFormSubmitToStream(event: any) {
    // Put together Stream's REST endpoint URL and Token from .env file, with feedId/networkSlug coming from iframe params.
    const streamPostUrl = `${process.env.REACT_APP_STREAM_USER_FEED_ENDPOINT}${props.feedParams.feedId}/?api_key=${process.env.REACT_APP_STREAM_API_KEY}`;
    const streamServerToken =
      process.env.REACT_APP_STREAM_REST_SERVER_TOKEN ?? 'invalid';
    // Post to this Tiger Network, which on Stream is User Feed with id `C--network_slug`
    const networkSlug = props.feedParams.feedId;
    // Post as this STREAM user/actor
    const userSlug = getUserIdFromJWTToken(props.feedParams.token);
    const actor = `SU:${userSlug}`;
    // Post this content
    const body = formData.body;
    const subject = formData.subject;

    var newPostRequestHeaders = new Headers();
    newPostRequestHeaders.append('Authorization', streamServerToken);
    newPostRequestHeaders.append('Stream-Auth-Type', 'jwt');
    newPostRequestHeaders.append('Content-Type', 'text/plain');

    // TODO get values from input form fields OR iframe params
    const newPostBody = JSON.stringify({
      actor: actor,
      attachments: {
        files: [],
        images: [],
        videos: [],
      },
      body: body,
      community: {
        avatar: null,
        id: 'TODO Get IRI from wordpress',
        name: 'TODO Get NAME from wordpress',
        slug: networkSlug,
      },
      customData: [],
      foreign_id: 'UUID set when posted via Symfony',
      isPromoted: false,
      object: 'IRI set when posted via Symfony',
      origin: null,
      settings: {
        postType: 'post',
      },
      subject: subject,
      target: '',
      verb: 'post',
    });

    // Prepare request headers and body to make a new post.
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: newPostRequestHeaders,
      body: newPostBody,
      redirect: 'follow',
    };

    // Use javascript's native `fetch` to send new post request and handle errors or success.
    fetch(streamPostUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log('result after posting was...', result);
        setFormData({
          subject: '',
          body: '',
        });
      })
      .catch((error) => console.log('error', error));
  }

  async function getSymfonyAccessToken() {
    console.log('getSymfonyAccessToken...');
    // TODO Should we get access token just once and keep it for sometime?
    // SEE: https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token
    const symfonyAccessTokenUrl = `${process.env.REACT_APP_SYMFONY_API_BASE_URL}${process.env.REACT_APP_SYMFONY_API_AUTH_ENDPOINT}`;

    // Prepare request headers and body to get access token.
    var accessTokenRequestHeaders = new Headers();
    accessTokenRequestHeaders.append('X-Client', 'integration');
    let clientId = `${process.env.REACT_APP_SYMFONY_API_CLIENT_ID}`;
    let clientSecret = `${process.env.REACT_APP_SYMFONY_API_CLIENT_SECRET}`;
    var accessTokenRequestBodyFormData = new FormData();
    accessTokenRequestBodyFormData.append('Client-ID', clientId);
    accessTokenRequestBodyFormData.append('Client-Secret', clientSecret);
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: accessTokenRequestHeaders,
      body: accessTokenRequestBodyFormData,
      redirect: 'follow',
    };

    // Use javascript's native `fetch` to get access token and handle errors or success.
    try {
      const response = await fetch(symfonyAccessTokenUrl, requestOptions);
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  async function handleFormSubmitToSymfony(event: any) {
    // Proceed only if you are able to get a valid access token from Symfony.
    const symfonyAccessToken = await getSymfonyAccessToken();
    // Put together Symfony's REST endpoint URL
    const symfonyNewPostUrl = `${process.env.REACT_APP_SYMFONY_API_BASE_URL}${process.env.REACT_APP_SYMFONY_API_NEW_POST_ENDPOINT}`;
    // TODO get token from backend, keep in browser, not use TEMP token from .env files
    // Authenticated header with access token and required header params like `X-Client`
    var newPostRequestHeaders = new Headers();
    newPostRequestHeaders.append(
      'Authorization',
      `Bearer ${symfonyAccessToken}`
    );
    newPostRequestHeaders.append('X-Client', 'integration');
    newPostRequestHeaders.append('accept', 'application/ld+json');
    newPostRequestHeaders.append('Content-Type', 'application/ld+json');
    // Symfony UUID's of user/owner and community come from wordpress via iframe params
    // We use those UUID's as IRI's by appending them to base IRI from .env files
    const newPostBody = JSON.stringify({
      title: formData.subject,
      body: formData.body,
      isPromoted: false,
      owner: `${process.env.REACT_APP_POST_USER_IRI_PREFIX}${props.feedParams.userUuid}`,
      community: `${process.env.REACT_APP_POST_NETWORK_IRI_PREFIX}${props.feedParams.symfonyUuid}`,
    });
    // Prepare request headers and body to make a new post.
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: newPostRequestHeaders,
      body: newPostBody,
      redirect: 'follow',
    };

    // Use javascript's native `fetch` to send new post request and handle errors or success.
    // TODO build endpoint from `.env` and passed in params
    fetch(symfonyNewPostUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log('result after posting was...', result);
        setFormData({
          subject: '',
          body: '',
        });
      })
      .catch((error) => console.log('error', error));
  }

  function handleFormSubmitCancel(event: any) {
    event.preventDefault();
    setFormData({
      subject: '',
      body: '',
    });
    props.formToggle();
  }

  return (
    <React.Fragment>
      <Card
        sx={{
          // maxWidth: 780,
          minWidth: 400,
          marginBottom: 5,
          border: 1,
          borderColor: palette.primary.main,
        }}
        variant='outlined'
      >
        <CardHeader
          title='Create a new post'
          subheader={`in ${getNetworkDisplayNameFromSlug(
            props.feedParams.feedId
          )}`}
          action={
            <IconButton aria-label='settings' onClick={handleFormSubmitCancel}>
              <CloseIcon />
            </IconButton>
          }
          sx={{
            paddingBottom: 0,
          }}
        />
        <CardContent>
          <form
            className='form-todo'
            // onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              label='Subject'
              id='subject'
              name='subject'
              margin='normal'
              value={formData.subject}
              onChange={handleFormChange}
            />
            <TextField
              fullWidth
              label='Body'
              id='body'
              name='body'
              onChange={handleFormChange}
              value={formData.body}
              margin='normal'
              multiline
              minRows={3}
              maxRows={10}
            />
          </form>
        </CardContent>
        <CardActions
          sx={{
            padding: '0px 16px 16px 16px',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='outlined' onClick={handleFormSubmitCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            endIcon={<SendIcon />}
            onClick={handleFormSubmitToSymfony}
            sx={{
              color: 'white',
            }}
          >
            Post
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default NewPostForm;
