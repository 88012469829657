import React from 'react';
import { useLocation } from 'react-router-dom';
// import { TDiscussionParams } from './tiger21/components/ShowAllDiscussionParams';
// import ShowAllDiscussionParams from './tiger21/components/ShowAllDiscussionParams';
import DiscussionFeed from './tiger21/components/DiscussionFeed';
import { TDiscussionFeedParams } from './tiger21/components/DiscussionFeed';
// import tigerLogo from './assets/app-icon.png';
import './App.css';
import './styles/tiger21-styles.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// A custom hook that builds on useLocation to parse query string params.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#BC9D58',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
});

function App() {
  // Read query string params and get each key's value.
  let query = useQuery();
  const discussionFeedParams: TDiscussionFeedParams = {
    appId: query.get('appId') ?? 'handle-value-not-passed',
    apiKey: query.get('apiKey') ?? 'handle-value-not-passed',
    token: query.get('token') ?? 'handle-value-not-passed',
    feedGroup: query.get('feedGroup') ?? 'handle-value-not-passed',
    feedId: query.get('feedId') ?? 'handle-value-not-passed',
    userUuid: query.get('userUuid') ?? 'handle-value-not-passed',
    symfonyUuid: query.get('symfonyUuid') ?? 'handle-value-not-passed',
  };
  // TODO work with Raju to rename symfonyUuid to symfonyCommunityUuid

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        {/* <ShowAllDiscussionParams allDiscussionParams={discussionParams} /> */}
        <DiscussionFeed feedParams={discussionFeedParams} />
      </div>
    </ThemeProvider>
  );
}

export default App;
