import React from 'react';
// Custom Tiger21 imports
import LikeButton from './LikeButton';
// STREAM imports
// todo
// Material UI imports
import { useTheme } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ReplyIcon from '@mui/icons-material/Reply';

const PostEngagementButtons = (props: any) => {
  const { palette } = useTheme();

  const handleExpandClick = () => {
    console.log('TODO how should comment and reply button differ?');
    // setExpanded(!expanded);
  };

  const handleMainPostReply = () => {
    console.log('TODO handleMainPostReply');

    // TODO temp scroll to bottom, then useRef

    // DONT indiscrimately scroll to window bottom
    // window.scroll({
    //   top: document.body.offsetHeight,
    //   left: 0,
    //   behavior: 'smooth',
    // });
    // setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <CardActions>
        <Stack
          direction='row'
          justifyContent='space-around'
          alignItems='center'
          sx={{
            width: '100%',
            borderTop: 1,
            borderBottom: 1,
            borderColor: palette.primary.main,
          }}
        >
          <LikeButton activity={props.activity} />
          <Button onClick={handleExpandClick} startIcon={<InsertCommentIcon />}>
            Comments
          </Button>
          <Button startIcon={<ReplyIcon />} onClick={handleMainPostReply}>
            Reply
          </Button>
        </Stack>
      </CardActions>
    </React.Fragment>
  );
};

export default PostEngagementButtons;
