import React from 'react';
// Custom Tiger21 imports
import PostEngagementButtons from './PostEngagementButtons';
import CommentItemLevelOne from './CommentItemLevelOne';
import NewLevelOneCommentForm from './NewLevelOneCommentForm';
// STREAM imports
import { CommentList } from 'react-activity-feed';
// Material UI imports
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import { IconButtonProps } from '@mui/material/IconButton';
// import InsertCommentIcon from '@mui/icons-material/InsertComment';
// import ReplyIcon from '@mui/icons-material/Reply';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Box from '@mui/material/Box';
// Other imports
import moment from 'moment';

// Export types related to this component
// TODO make activity be of a type with all custom key/values from Stream
export type DiscussionPostProps = {
  activity: any;
};

const DiscussionPost = (props: DiscussionPostProps) => {
  const { palette } = useTheme();

  if (props.activity.settings?.postType === 'post') {
    return (
      <React.Fragment>
        <Card
          sx={{
            // maxWidth: 780,
            minWidth: 400,
            marginBottom: 5,
            border: 1,
            borderColor: palette.primary.main,
          }}
          variant='outlined'
        >
          <CardHeader
            avatar={
              <Avatar
                alt={props.activity.actor.data.name}
                src='/static/images/avatar/1.jpg'
                //   src={activity.actor.data.null}
              />
            }
            action={
              <IconButton aria-label='settings'>
                <MoreVertIcon />
              </IconButton>
            }
            title={props.activity.actor.data.name}
            subheader={moment.utc(props.activity.time).fromNow()}
          />
          {/* TODO Show media only if Images, Videos, or Files are present */}
          {/* <CardMedia
            component='img'
            height='194'
            image='https://placehold.jp/e51f33/ffffff/1080x1080.png'
            alt='Paella dish'
          /> */}
          <CardContent>
            <Typography variant='h6'>{props.activity.subject}</Typography>
            {/* TODO see how we are making newlines work in mobile app */}
            <Typography variant='body2'>{props.activity.body}</Typography>
            {/* TODO images, videos, files here */}
          </CardContent>
          <PostEngagementButtons activity={props.activity} />
          <CardContent className='tiger-comments-thread'>
            <CommentList
              activityId={props.activity.id}
              reverseOrder={true}
              CommentItem={(props) => (
                <CommentItemLevelOne comment={props.comment} />
              )}
            />
            <NewLevelOneCommentForm
              activity={props.activity}
              placeholder='Write a comment...'
            />
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
  // Other posts types are not yet supported on the Web because they depend on mobile components.
  return <React.Fragment></React.Fragment>;
};

export default DiscussionPost;
