import React, { useRef, useState, FormEvent, useEffect } from 'react';
// Custom Tiger21 Imports
// todo
// STREAM Imports
import { EnrichedActivity, Activity } from 'getstream';
import {
  Button,
  Textarea,
  TextareaProps,
  useFeedContext,
  useTranslationContext,
  CommentFieldProps,
} from 'react-activity-feed';
// Material UI Imports
// todo
// Other Misc Imports
import classNames from 'classnames';
import { Data as EmojiDataSet } from 'emoji-mart';

export type NewLevelTwoCommentFormProps = {
  parentComment?: any;
  placeholder?: any;
  activity?: any;
  onSuccess?: any;
  className?: any;
  style?: any;
  emojiData?: any;
  trigger?: any;
  childInputRef?: any;
};

const NewLevelTwoCommentForm = (props: NewLevelTwoCommentFormProps) => {
  const feed = useFeedContext();
  const { t } = useTranslationContext();
  const [text, setText] = useState<string>();
  const textareaReference = props.childInputRef;
  // NOTE: Stream's pre-coded CommentField has a `useRef<HTMLTextAreaElement>` directly inside the
  // CommentField component. But we want to click "Reply" button in CommentEngagementButtons component,
  // which toggles `NewLevelTwoCommentForm`, and then autofocus on the textarea in this component.
  // So we create a reference `useRef<HTMLTextAreaElement>` in the parent component instead,
  // and pass it down here as `props.childInputRef`.
  // SEE: https://github.com/GetStream/react-activity-feed/blob/v1.4.0/src/components/CommentField.tsx#L46
  // SEE: TODO blog or video link about "complicated" useEffect, useRef, toggle, autofocus, etc.

  const handleFormSubmit = async (
    event: FormEvent<HTMLFormElement> | KeyboardEvent
  ) => {
    event.preventDefault();

    if (!text) return;

    try {
      await feed.onAddChildReaction('comment', props.parentComment.id, {
        text,
      });
    } catch (error) {
      console.error(error);
    }

    setText('');
    props.onSuccess?.();
  };

  useEffect(() => {
    if (!textareaReference.current) return;
  }, []);

  return (
    <React.Fragment>
      <form
        onSubmit={handleFormSubmit}
        className={classNames('raf-comment-field', props.className)}
        style={props.style}
      >
        {/* TODO will be good if we show Member avatar next to new comment form? nested reply? like facebook? */}
        {/* TODO if yes, then use MUI avatar? But with Member photo from Stream? */}
        {/* {image && <Avatar image={image} circle size={39} />} */}
        <div className='raf-comment-field__group'>
          <Textarea
            rows={1}
            value={text}
            placeholder={props.placeholder ?? t('Start Typing...')}
            onChange={(event) => {
              const target = event.target as HTMLTextAreaElement;
              setText((pv) => target.value ?? event.currentTarget.value ?? pv);
            }}
            emojiData={props.emojiData}
            trigger={props.trigger}
            innerRef={textareaReference}
          />
          <Button buttonStyle='primary' disabled={!text} type='submit'>
            Reply
          </Button>
        </div>
        {/* TODO use mui button? probably not needed tho */}
      </form>
    </React.Fragment>
  );
};

export default NewLevelTwoCommentForm;
