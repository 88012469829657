import React from 'react';
// Custom Tiger21 imports
import LikeButton from './LikeButton';
import NewLevelTwoCommentForm from './NewLevelTwoCommentForm';
// STREAM imports
// todo
// Material UI imports
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ReplyIcon from '@mui/icons-material/Reply';

const CommentEngagementButtons = (props: any) => {
  const [showNestedReplyBox, setShowNestedReplyBox] = React.useState(false);
  const childInputRef = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    childInputRef.current && childInputRef.current.focus();
  });

  function toggleNestedReplyBox() {
    setShowNestedReplyBox((prevValue) => !prevValue);
    // NOTE: When state `showNestedReplyBox` changes our component re-renders.
    // After re-render with new value `true` is complete, two things happen,
    // "side effect" code in our `useEffect` hook runs, and `childInputRef.current`
    // is not null anymore, has the actual ref of input, because it was toggled ON.
    // So we do the autofocusing of the input in the function returned by `useEffect`.
  }

  return (
    <React.Fragment>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={2}
      >
        <LikeButton reaction={props.comment} />
        <Button onClick={toggleNestedReplyBox} startIcon={<ReplyIcon />}>
          Reply
        </Button>
      </Stack>
      {showNestedReplyBox && (
        <Box sx={{ ml: props.showSameLevelReply ? 0 : 6 }}>
          <NewLevelTwoCommentForm
            parentComment={props.parentComment}
            placeholder='Write a reply...'
            childInputRef={childInputRef}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default CommentEngagementButtons;
