import React from 'react';
// Custom Tiger21 imports
import CommentEngagementButtons from './CommentEngagementButtons';
// STREAM imports
import { CommentItem } from 'react-activity-feed';
// Material UI imports
import Box from '@mui/material/Box';

const CommentItemLevelTwo = (props: any) => {
  return (
    <React.Fragment>
      <Box sx={{ ml: 5 }}>
        <CommentItem comment={props.comment} />
        <CommentEngagementButtons
          comment={props.comment}
          parentComment={props.parentComment}
          showSameLevelReply={true}
        />
      </Box>
    </React.Fragment>
  );
};

export default CommentItemLevelTwo;
