import React from 'react';
// Custom Tiger21 imports
// TODO import { Color } from './Icons';
// STREAM imports
import {
  LikeButtonProps as GetstreamLikeButtonProps,
  ReactionToggleIcon,
  useFeedContext,
} from 'react-activity-feed';
// Material UI imports
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

// TODO move like button colors to theme customization
const Color = {
  active: '#FA2F53',
  inactive: '#BC9D58',
};

const LikeButton = (props: GetstreamLikeButtonProps) => {
  const feed = useFeedContext();

  React.useEffect(() => {
    if (!props.reaction && !props.activity)
      console.warn(
        'LikeButton requires an activity or reaction to work properly'
      );
    if (props.reaction && props.activity)
      console.warn(
        'LikeButton requires only one of the activity or reaction to work properly'
      );
  }, []);

  return (
    <React.Fragment>
      <ReactionToggleIcon
        className={props.className}
        style={props.style}
        counts={
          props.reaction?.children_counts ?? props.activity?.reaction_counts
        }
        own_reactions={
          props.reaction?.own_children ?? props.activity?.own_reactions
        }
        kind='like'
        onPress={() => {
          if (props.reaction)
            return feed.onToggleChildReaction('like', props.reaction as any);
          if (props.activity)
            return feed.onToggleReaction('like', props.activity as any);
          return Promise.resolve();
        }}
        activeIcon={<FavoriteIcon style={{ color: Color.active }} />}
        inactiveIcon={<FavoriteBorderIcon style={{ color: Color.inactive }} />}
        labelSingle='like'
        labelPlural='likes'
      />
    </React.Fragment>
  );
};

export default LikeButton;
