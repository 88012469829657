import React from 'react';
// Custom Tiger21 imports
import DiscussionPost from './DiscussionPost';
import NewPostForm from './NewPostForm';
// STREAM imports
import {
  StreamApp,
  FlatFeed,
  InfiniteScrollPaginator,
} from 'react-activity-feed';
// Material UI imports
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

// Export types related to this component
// TODO better comment here
export type TDiscussionFeedParams = {
  appId: string;
  apiKey: string;
  token: string;
  feedGroup: string;
  feedId: string;
  userUuid: string;
  symfonyUuid: string;
};
// TODO better comment here
export type TDiscussionFeedProps = {
  feedParams: TDiscussionFeedParams;
};

const DiscussionFeed = (props: TDiscussionFeedProps) => {
  const [showNewDiscussionsPostForm, setShowNewDiscussionsPostForm] =
    React.useState(false);

  function toggleNewDiscussions() {
    setShowNewDiscussionsPostForm((prevState) => !prevState);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <React.Fragment>
      {/* TODO wrapping box or container of best maxWidth UX here */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
        {!showNewDiscussionsPostForm && (
          <Fab
            variant='extended'
            color='primary'
            className='button-add-new-post'
            onClick={toggleNewDiscussions}
          >
            <AddIcon sx={{ mr: 1 }} />
            New Discussion
          </Fab>
        )}
      </Box>
      <StreamApp
        appId={props.feedParams.appId}
        apiKey={props.feedParams.apiKey}
        token={props.feedParams.token}
      >
        {showNewDiscussionsPostForm && (
          <React.Fragment>
            <NewPostForm
              feedParams={props.feedParams}
              formToggle={toggleNewDiscussions}
            />
          </React.Fragment>
        )}

        <FlatFeed
          notify
          feedGroup={props.feedParams.feedGroup}
          userId={props.feedParams.feedId}
          options={{
            limit: 6,
            withOwnChildren: true,
            withRecentReactions: true,
          }}
          Paginator={InfiniteScrollPaginator}
          Activity={({ activity, feedGroup, userId }) => (
            <DiscussionPost activity={activity} />
          )}
        />
      </StreamApp>

      {/* {!showNewDiscussionsPostForm && (TODO BUTTON HERE)} */}
    </React.Fragment>
  );
};

export default DiscussionFeed;
